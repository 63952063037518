import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import auth from './include/auth';
// import ReCAPTCHA from 'react-google-recaptcha-enterprise';
const parseUrl = require('parse-url');

axios.interceptors.request.use(
    (request) => {
        const { origin } = new URL(request.url);
        const allowedOrigins = [process.env.REACT_APP_API_URL];
        const token = localStorage.getItem('token');
        if (allowedOrigins.includes(origin) && token != null) {
            request.headers.authorization = `Bearer ${token}`;
        }
        return request;
    },
    (error) => {
        return Promise.reject(error);
    },
);

const blacklist = ['/discord/me'];

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            const { status, headers } = error.response;
            console.log(error.response);

            if (status != 401) return Promise.reject(error);
            else {
                if (!headers['x-authorization-check']) {
                    const url = parseUrl(error.response.config.url);
                    if (!blacklist.includes(url.pathname)) {
                        auth.check().then((result) => {
                            if (result.success == false) {
                                auth.destroy();
                                window.location.href = '/login';
                            }
                        });
                    } else return Promise.reject(error);
                }
            }
        }
    },
);

const url = parseUrl(window.location.href);
console.log(document.referrer, "oie");
if(url && url.query && url.query.ref) {
    localStorage.setItem('r', url.query.ref);
    if(document.referrer && parseUrl(document.referrer)) {
        const urlRef = parseUrl(document.referrer);
        urlRef.resource = urlRef.resource.replace('www.', '');
        localStorage.setItem('rs', urlRef.resource);
    } else {
        localStorage.removeItem('rs');
    }
}

// function onChange(value) {
//     console.log('********************* Captcha value:', value);
// }

const app = (
    <BrowserRouter>
        {/* <ReCAPTCHA
            sitekey="6Ldj0zkjAAAAAOrSJfrdXApHMnVwJsOlWIUvGI_e"
        /> */}
        <App />
    </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
