import axios from 'axios';
import React from 'react';

class Authentication {
    constructor() {
        this.AuthContext = React.createContext();
        this.context = undefined;
        this.token = this._storageGet('token');
        this.username = this._storageGet('username');
        this.subServer = this._storageGet('subserver');
    }

    getSubServer() {
        return this.subServer;
    }

    setSubServer(subServer) {
        this.subServer = subServer;
        localStorage.setItem('subserver', subServer);
    }

    setContext(context) {
        this.context = context;
    }

    updateContextUser(user) {
        const obj = {
            public_username: this.context.state.user.public_username | '',
            gid: this.context.state.user.gid | 0,
        };

        this.context.setState({ user });
    }

    _endpoint(endpoint, discord = false) {
        if (discord) {
            return `${process.env.REACT_APP_DISCORD_API_URL}${
                endpoint.startsWith('/') ? endpoint : `/${endpoint}`
            }`;
        }
        
        return `${process.env.REACT_APP_API_URL}${
            endpoint.startsWith('/') ? endpoint : `/${endpoint}`
        }`;
    }

    _storageGet(key) {
        const result = localStorage.getItem(key);
        if (result != null && result != undefined && result != '')
            return result;
        else return undefined;
    }

    authenticate(token) {
        if (token != null && token != undefined) {
            this.token = token;
            localStorage.setItem('token', token);
            this.getUserInfo().then((result) => {
                const { data } = result;
                this.updateContextUser(data);
            });
        } else throw new Error('bearer token missing');
    }

    destroy() {
        this.token = undefined;
        this.username = undefined;
        localStorage.removeItem('token');
    }

    isLogged() {
        if (this.token) return true;
        else return false;
    }

    refresh(opt = {}) {
        const options = {
                history: undefined,
                redirect: undefined,
                ...opt,
            },
            { history, redirect } = options;
        const check = this.check();
        if (check.success) {
            this.username = check.username;
        } else {
            this.destroy();
            if (history && redirect) history.push(redirect);
        }
    }

    check() {
        return new Promise((res, rej) => {
            const payload = { success: undefined };
            axios
                .get(this._endpoint('check'))
                .then((result) => {
                    payload.username = result.data.username;
                    payload.success = true;
                })
                .catch((error) => {
                    if (!('success' in payload)) rej(error);
                    payload.success = false;
                })
                .finally(() => {
                    res(payload);
                });
        });
    }

    async getStreamers() {
        return await axios.get(this._endpoint('streams'));
    }

    async forgot(email) {
        const result = await axios.post(this._endpoint('forgot'), {
            email,
        });

        return result;
    }

    async forgotConfirm(passthrough) {
        const result = await axios.post(
            this._endpoint(`forgot/${passthrough}`),
        );

        return result;
    }

    async login(username, password, opt = {}) {
        const _this = this;
        window.grecaptcha.enterprise.ready(function () {
            window.grecaptcha.enterprise
                .execute('6Ldj0zkjAAAAAOrSJfrdXApHMnVwJsOlWIUvGI_e', {
                    action: 'login',
                })
                .then(async function (gret) {
                    const options = {
                            history: undefined,
                            redirect: undefined,
                            ...opt,
                        },
                        { history, redirect } = options;

                    const result = await axios.post(_this._endpoint('login'), {
                        username,
                        password,
                        gret,
                    });

                    const { token } = result.data;
                    _this.authenticate(token);
                    _this.username = username;
                    if (history && redirect) history.push(redirect);
                    return _this;
                });
        });
    }

    async logout() {
        axios.get(this._endpoint('logout')).then(() => {
            this.destroy();
            window.location.href = '/login';
            return true;
        });
    }

    async changePassword(oldPassword, password) {
        axios
            .post(this._endpoint('changePassword'), { oldPassword, password })
            .then(() => {
                return this;
            });
    }

    async getUserInfo() {
        return await axios.get(this._endpoint('account'));
    }

    async getVoteInfo() {
        return await axios.get(this._endpoint('vote'));
    }

    async doVote(tid) {
        return await axios.post(this._endpoint(`vote/${tid}`));
    }

    async voteBuy() {
        return await axios.get(this._endpoint('vote/buy'));
    }

    async getOnlineCount() {
        return await new Promise((res, rej) => {
            axios.get(this._endpoint('online')).then((result) => {
                const { data } = result;
                res(data.toLocaleString().replace(/,/g, '.'));
            }).catch((err) => {
                rej(err);
            });
        })
    }

    async getGlobalOnlineCount() {
        return await new Promise((res, rej) => {
            axios.get('https://api.global.ragna4th.com/online').then((result) => {
                const { data } = result;
                res(data.toLocaleString().replace(/,/g, '.'));
            }).catch((err) => {
                rej(err);
            });
        })
    }

    async resetPosition() {
        return await axios.get(this._endpoint('resetPosition'));
    }

    async resetLook() {
        return await axios.get(this._endpoint('resetLook'));
    }

    discordMe() {
        return new Promise((res, rej) => {
            axios
                .get(this._endpoint('/discord/me'))
                .then((response) => {
                    const { data } = response;
                    res(data);
                })
                .catch((err) => {
                    rej(err);
                });
        });
    }

    discordLink(code) {
        return new Promise((res, rej) => {
            axios
                .get(this._endpoint(`/discord/oauth?code=${code}`))
                .then((result) => {
                    res(result);
                })
                .catch((err) => {
                    rej(err);
                });
        });
    }

	debouncedDLink(code) {
		this.debounce((code) => this.discordLink(code))
	}
}

export default new Authentication();
