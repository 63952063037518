import React from 'react';

// Root Include
const Root = React.lazy(() => import('./pages/Home/indexRoot'));

//Special
const PageError = React.lazy(() => import('./pages/Pages/Special/PageError'));

//Auth Pages
const Login = React.lazy(() => import('./pages/Pages/AuthPages/Login'));

const Register = React.lazy(() => import('./pages/Pages/AuthPages/Register'));

const PageCoverRePassword = React.lazy(() =>
    import('./pages/Pages/AuthPages/PageCoverRePassword'),
);
const PageRecoveryPassword = React.lazy(() =>
    import('./pages/Pages/AuthPages/PageRecoveryPassword'),
);
const PageRePasswordThree = React.lazy(() =>
    import('./pages/Pages/AuthPages/PageRePasswordThree'),
);

// Import all components
const Freelancer = React.lazy(() => import('./pages/Freelancer/index'));

//Account
const PageProfile = React.lazy(() =>
    import('./pages/Pages/Account/PageProfile'),
);

const PageProfileVote = React.lazy(() =>
    import('./pages/Pages/Account/PageProfileVote'),
);

// Black Friday
// const BlackFriday = React.lazy(() => import('./pages/Shop/index'));

const Anniversary = React.lazy(() => import('./pages/Shop/anniversary'));
// const BlackFriday = React.lazy(() => import('./pages/Shop/black-friday'));

const authFrame = React.lazy(() => import('./pages/iFrame/index'));

// Server Selection
const ServerSelection = React.lazy(() => import('./pages/Home/ServerSelection'));

const routes = [
    //User Pages
    // { path: '/select', component: ServerSelection, isTopbarDark: false, isWithoutLayout: true },
    { path: '/', component: Freelancer, isTopbarDark: false },
    // { path: '/black-friday', component: BlackFriday, isTopbarDark: false },
    { path: '/3-anos', component: Anniversary, isTopbarDark: false },
    {
        path: '/login',
        component: Login,
        isWithoutLayout: true,
    },
    {
        path: '/forgot/:email?',
        component: PageRePasswordThree,
        isWithoutLayout: true,
    },
    {
        path: '/register',
        component: Register,
        isWithoutLayout: true,
    },

    // //Page Profile
    { path: '/account', component: PageProfile },
    { path: '/vote', component: PageProfileVote },

    {
        path: '/frame',
        component: authFrame,
        isWithoutLayout: true,
    },

    //Index root

    { path: '/', component: Root, isWithoutLayout: true, exact: true },
    { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
