import React, { Component, Suspense } from 'react';
import Layout from './components/Layout/';
import {
    Route,
    Switch,
    BrowserRouter as Router,
    withRouter,
    Redirect,
} from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';

// Import Css
import './assets/css/materialdesignicons.min.css';
import './Apps.scss';

//import "./assets/css/colors/default.css";

// Include Routes
import routes from './routes';

import auth from './include/auth';

function withLayout(WrappedComponent, hasDarkTopBar) {
    // ...and returns another component...
    return class extends React.Component {
        render() {
            return (
                <Layout hasDarkTopBar={hasDarkTopBar}>
                    <WrappedComponent {...this.props}></WrappedComponent>
                </Layout>
            );
        }
    };
}

const history = createHistory();
history.listen((_location, action) => {
	if (action !== 'REPLACE') {
		window['strum']('routeChange', window.location.href);
	}
})

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                public_username: '',
                gid: 0,
            },
        };

        const blacklist = ['/frame'];

		

        if (auth.isLogged) {
            auth.check().then((data) => {
                if (data.success === true) {
                    auth.getUserInfo().then((result) => {
                        const { data } = result;
                        const user = data;
                        this.setState({ user });
                    });
                }
            });
        }

        auth.setContext(this);
    }

    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                </div>
            </div>
        );
    };
    render() {
        return (
            <auth.AuthContext.Provider value={this.state}>
                <React.Fragment>
                    <Router history={history}>
                        <Suspense fallback={this.Loader()}>
                            <Switch>
                                <Route path='/discord' component={() => {
                                    window.location.href = 'https://discord.gg/uC6upJnWmp';
                                    return null;
                                }} />
                                    
                                {routes.map((route, idx) =>
                                    route.isWithoutLayout ? (
                                        <Route
                                            path={route.path}
                                            exact={route.exact}
                                            component={route.component}
                                            key={idx}
                                        />
                                    ) : (
                                        <Route
                                            path={route.path}
                                            exact
                                            component={withLayout(
                                                route.component,
                                                route.isTopbarDark,
                                            )}
                                            key={idx}
                                        />
                                    ),
                                )}                                
                            </Switch>
                        </Suspense>
                    </Router>
                </React.Fragment>
            </auth.AuthContext.Provider>
        );
    }
}

export default withRouter(App);
